:root {
  --navbar-text: var(--night);
  --button-bg: var(--eggplant); 
  --button-text: var(--powder-blue);
}

  
nav {
  position: static;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 30px; /* Increased padding for more height and spacing */
}

.navbar {
  color: var(--navbar-text);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #C69FD4;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}
  
.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  align-self: flex-start;
  margin-left: 0; /* Set to zero */
  margin-top: 0; /* Set to zero */
}
  
.navbar-logo {
  width: 150px;
  height: auto;
  margin-top: 10px;
}
.navbar-menu {
  list-style: none;
  display: flex;
}
.navbar-item {
  display: inline-block;
  margin-left: 10px; /* Increase spacing between items */
  font-size: 16px; /* Slightly larger font */
  padding: 5px 10px;
  border-radius:5px;
  transition: background-color 0.3s ease;
}

.navbar-item:hover {
  background-color:#EFE9E6; 
}


.navbar-links {
  color: var(--navbar-text);
  text-decoration: none;
  font-weight: 500; 
}

.user-info {
  display: flex;
  align-items: center;
  margin-left: auto; /* Pushes to the right end */
}
  
  .logout-button {
    padding: 8px 16px;
    border: none;
    background-color: #C69FD4;
    color: #f9f99f;
    cursor: pointer;
    border-radius: 5px;
  }

  .logout-button:hover{
    background-color: #B380C6;
    transition: 0.3s ease;
  }

  .logout-container {
    margin-left: auto;
    margin-right: 0;
  }
  .hamburger {
    display: none; /* Hide by default */
    font-size: 1.5em;
    cursor: pointer;
  }
  
  .navbar-menu.show {
    display: flex;
  }
  
  @media (max-width: 600px) {
    .navbar-container {
      flex-direction: column;
    }
  
    .hamburger {
      display: block; /* Show on small screens */
    }
  
    .navbar-menu {
      display: none; /* Hide by default on small screens */
      flex-direction: column;
    }
  
    .navbar-menu.show {
      display: flex; /* Show when the hamburger menu is clicked */
    }
  }