:root {
  --wisteria: #C69FD4;
  --powder-blue: #97BCD8;
  --night: #0D160B;
  --timberwolf:#DFD3CD;
  --eggplant:#5C3349;
}

.full-height {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

@font-face {
  font-family: 'GeistSans';
  src: url('./fonts/Geist-Regular.woff2') format('woff2'),
       url('./fonts/Geist-Regular.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GeistSans';
  src: url('./fonts/Geist-Bold.woff2') format('woff2'),
       url('./fonts/Geist-Bold.otf') format('otf');
  font-weight: bold;
  font-style: normal;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-email {
  margin-right: 10px;
  color: #fff;
}

.upload-info{
  color: var(--powder-blue);
}

.login-register {
  margin-right: auto;
}

body, input {
  font-family: 'GeistSans', Arial, sans-serif;
  font-size: 1em;
  line-height: 1.6;
  background:#f9f9f9; 
}

/*.upload-page-background {
  background-image: url('./Stripe.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}*/

.background-video {
  position: fixed; /* This will position the video relative to the viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* This will put the video behind other content */
  opacity: 40%;
  background-color: #f9f9f9;
}

.upload-page {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  color: var(--night);
  margin: 0 auto;
  padding: 20px;
  max-width: 60%;
}

.left-section {
  flex: 1;
}

.main-content {
  flex: 1 0 auto;
}

.full-height {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

h1 {
  font-size: 3em;
  font-weight: 800;
  margin-bottom: 20px;
  align-content: center;
  justify-content: center;
  line-height: 1;
}

h2 {
  font-size: 1.3em;
  margin-bottom: 20px;
  align-content: center;
  justify-content: center;
  font-weight: 400;
}

h3 {
  font-size: 1.5em;
  margin-bottom: 50px;
}

form {
  display: flex;
  flex-direction: column;
}

/*input:invalid {
  border: 2px solid red;
}*/

.custom-file-upload{
  background-color: var(--powder-blue);
  color: white;
  padding: 20px 40px;
  border: 2px solid var(--powder-blue);
  cursor: pointer;
  border-radius: 10px;
  margin-top: 10px;
  font-size: 1.2em;
}

.pricing-upload-page-button{
  background-color: var(--wisteria);
  color:#f9f9f9;
  padding: 10px 20px;
  border: 2px solid var(--powder-blue);
  cursor: pointer;
  border-radius: 10px;
  font-size: 1em;
}

.pricing-upload-page-button:hover {
  border: 2px dashed (var--powder-blue);
  transition: 0.3s ease-in;
  background-color:#C69FD4;
  color: #f9f9f9;
}
.pricing-button{
  background-color: var(--wisteria);
  color:#f9f9f9;
  padding: 10px 40px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  transition: 0.3s;
  margin-top: 10px;
  font-size: 1em;
}
.pricing-button:hover, .pricing-button:active{
  background-color: #B380C6;

}


.custom-file-upload:hover {
  background-color: var(--wisteria);
  color: white;
  border:2px dashed var(--powder-blue);
  transition: 0.3s ease-in;
}

button.customer-file-upload:disabled {
  background-color: var(--eggplant);
  cursor: not-allowed;
}

.hidden-input {
  height: 0;
  display: none;
  width: 0;
}

.login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.content {
  margin-top: 10px;
}

.error-message {
  font-family: 'GeistSans';
  margin-top: 10px;
  color: red; /* Change the text color to red */
  font-weight: bold; /* Make the text bold */
  background-color: #f8d7da; /* Add a light red background */
  border: 1px solid #f5c6cb; /* Add a border */
  border-radius: 4px; /* Round the corners */
  padding: 10px; /* Add some padding */
  margin-bottom: 10px; /* Add some margin at the bottom */
}

.success-message {
  margin-top: 10px;
  color: green; /* Change the text color to green */
  font-weight: bold; /* Make the text bold */
  background-color: #d4edda; /* Add a light green background */
  border: 1px solid #c3e6cb; /* Add a border */
  border-radius: 4px; /* Round the corners */
  padding: 10px; /* Add some padding */
  margin-bottom: 10px; /* Add some margin at the bottom */
}
input[type="email"], input[type="password"] {
  box-sizing: border-box;
  padding: 10px;
  border: 2px solid var(--wisteria);
  border-radius: 5px;
  margin-bottom: 10px;
  width: 100%;
  font-size: 1em;
}


/* Modify the base button style for specific buttons */
.auth-button {
  margin: 5px 0;
  border: 1px solid ;
  border-radius: 10px;
  background-color: var(--wisteria);
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  vertical-align: middle;
  color: #f9f9f9;
  font-size: 1em;
}

.auth-button:hover{
  background-color:#B380C6;
  transition: 0.3s ease;
}

.auth-button.google {
  background-color: transparent;
  border: 2px solid var(--wisteria);
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  vertical-align: middle;
  color: #422434;
}

.auth-button.google:hover{
  transition: 0.3s ease;
  border: 2px dashed var(--powder-blue);
}

.auth-button.google img {
  height: 20px;
  margin-right: 10px;
  background-color: transparent;
  vertical-align: middle;
}

.divider-container {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.divider-line {
  flex-grow: 1;
  height: 2px;
  background-color: var(--powder-blue);
  border:none;
}

.divider-text {
  padding: 0 10px;
  color: var(--powder-blue);
}

.auth-container .auth-link {
  color: #422434;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s ease;
}

.auth-container .auth-link:hover {
  color: var(--powder-blue);
}

.auth-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.auth-form {
  margin: 20px 0;
}

.modal-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 200px;
  padding: 20px;
  background-color: var(--cream);
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  font-size: 1em;
}

.modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Modal input[type="email"] {
  box-sizing: border-box;
  padding: 10px;
  border: 2px solid var(--hookers-green);
  border-radius: 5px;
  margin-bottom: 10px;
  width: 100%;
  font-size: 1em;
}

.transcribing-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
}

.pricing-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 0 100px;
  gap:10px;
}

.balance-info {
  flex: 1;
  padding: 0 20 px 40px;
  text-align: center; 
}

.faq-info {
  flex: 1;
  padding-right: 100px;
  width: 60%;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.feature-container {
 display:grid;
 grid-template-columns: repeat(3, 1fr);
 gap: 20px;

}

.feature-card {
  flex-basis: 15%;
  margin-bottom: 20px;
  box-shadow: 0 8px 12px rgba(201, 160, 220, 0.1);;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  transition: transform 0.3s ease;
  background-color: #f9f9f9;
}


.feature-card:hover {
  transform: scale(1.05);
}

.feature-icon {
  font-size: 24px;
  margin-bottom: 20px;
}

.feature-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.feature-description {
  font-size: 16px;
}

.sections-wrapper {
  width: 100%;
}

/* Container styling */
.upload-container {
  /*background-image: url('./background.png');
  background-repeat: no-repeat;
  background-size: cover;*/
  background: 
  linear-gradient(0deg, rgba(176,224,230,0.5) 1px, transparent 1px), 
  linear-gradient(90deg, rgba(176,224,230 ,0.5)1px, transparent 1px), 
  #f9f9f9; /* replace with your desired background color */
  background-size: 75px 75px; /* adjust to control the size of the grid cells */
  width: 100%;
  border: 2px solid var(--powder-blue);
  padding: 0 0 50px;
  border-radius: 10px;
  display: flex; 
  flex-direction: column; 
  align-items: center;
  margin: 0 auto;
}

/* Upload text styling */
.upload-container p {
  font-size: 18px;
  color: var(--night);
  margin-bottom: 0px;
}
/* Upload button styling */
.upload-btn-wrapper {
  position: relative;
  align-items: center;
  display: flex;
  width: fit-content;
}

.icon-wrapper {
  margin-top: 40px;
  margin-right: 20px;
}
.file-icon {
  align-self: flex-auto;
  margin-top: 50px;
}


.upload-btn-wrapper button {
  background-color: var(--powder-blue);
  color: white;
  padding: 25px 50px;
  border: 2px solid var(--powder-blue);
  cursor: pointer;
  border-radius: 10px;
  margin-top: 50px;
  font-size:1.2em;
}

.minute-input{
    border-radius: 5px;
    margin-top: 20px;
}

.upload-btn-wrapper input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.input-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px; /* Adjust this value based on your needs */
}

.input-button-container input,
.input-button-container button {
  box-sizing: border-box;
}

/* Adjustments for devices with small screens */
@media (max-width: 600px) {
  .background-video {
    display: none; /* Hide the video */
  }
  .upload-container {
    padding: 10px;
    width: 80%;
  }

  .upload-container p {
    font-size: 14px;
  }

  .file-icon {
    width: 30px;
    height: 30px;
  }

  .upload-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color:#f9f9f9;
  }

  .feature-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjust as needed for mobile */
    gap: 20px;
    flex-direction: column;
    align-items: center;
  }

  .feature-card {
    flex-basis: 100%;
    margin: 10px 0;
    display: grid;
    grid-template-rows: auto 1fr auto; /* Adjust based on your content structure */
  }

  .upload-page {
    padding: 10px;
    max-width: 100%;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1em;
  }

  .custom-file-upload{
    padding: 10px 20px;
    font-size: 1em;
  }
}

@media (max-width: 375px) and (min-height: 812px) {

  .background-video {
    display: none; /* Hide the video */
  }
  .upload-page {
    padding: 10px;
    max-width: 100%;

  }

  .upload-container {
    width: 90%; /* Adjust as needed */
    padding: 10px; /* Adjust as needed */
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1em;
  }
  .custom-file-upload{
    padding: 10px 20px;
    font-size: 1em;
  }

  .feature-container {
    grid-template-columns: repeat(2, 1fr); /* Change to two column layout */
    grid-auto-rows: minmax(150px, auto); /* Consistent row height */
  }

  .feature-card {
    flex-basis: calc(50% - 20px); /* Take up half the width of the container, minus the margin */
    margin: 10px; /* Add some margin */
    display: grid;
    grid-template-rows: auto 1fr auto; /* Adjust based on your content structure */
  }
}