.disclosure-button {
  display: flex;
  justify-content: center; 
  align-items: center; 
  padding: 10px;
  background-color: var(--timberwolf);
  color: var(--night);
  transition: background-color 0.3s ease;
  border-radius: 15px 15px 15px 15px;
  border-color: var(--eggplant);
  cursor: pointer;
  padding-left: 30px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  padding: 0px 50px;
}

.disclosure-button:hover {
  background-color: var(--powder-blue);
}

.disclosure-panel {
  transition: max-height 0.3s ease;
  overflow: hidden;
  border-radius: 0 0 1px 1px;
  padding: 10px;
  background-color: var(--night);
}

.transcription-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: var(--night);
  background-color: #f4f4f4;
}

.disclosure-button-text {
  max-width: 90%; /* Adjust as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
  font-size: 2em;
}

.small-icon {
  width: 50px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
}