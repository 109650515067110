.customer-banner {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 12px rgba(201, 160, 220, 0.1);
    margin: 40px 0;
    border-radius: 10px;
  }
  .customer-logo {
    max-width: 120px;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  .customer-logo:hover {
    transform: scale(1.1);
  }

.banner-text {
    margin-right: 10px;
    font-size: 1em;
    font-weight:300;
    color: var(--night);
  }


  @media (max-width: 600px) {
  .customer-logo {
    max-width: 80px; /* Adjust as needed */
  }

  .banner-text {
    display: none; 
  }
}